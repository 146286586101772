<template>
    <button id="FullscreenButton" v-if="!onHomePage" :class="{'FullscreenButton--isFullscreen': isFullscreen}" @click="toggleFullscreen" aria-hidden="true"></button>
</template>
<script>
export default {
    props: ["onHomePage"],
    methods: {
        toggleFullscreen() {
            app.api.Utils.toggleFullscreen();
        }
    },
    computed: {
        isFullscreen() {
            return this.$store.getters.isFullscreen
        }
    }
}
</script>
<style lang="scss">
    #FullscreenButton {
        position: absolute;
        left: 180px;
        background-image: url(@/assets/img/ui/fullscreen.png);

        &:hover {
            background-image: url(@/assets/img/ui/fullscreen-hover.png);
        }

        &.FullscreenButton--isFullscreen {
            background-image: url(@/assets/img/ui/fullscreen-active.png);

            &:hover {
                background-image: url(@/assets/img/ui/fullscreen-active-hover.png);
            }
        }
    }
</style>