<template>
  <transition name="toggleContentComponent">
    <article class="ContentWindow contentSidescroll" v-if="ready">
        <button class="closeButton" @click="closeComponent"></button>
        <Breadcrumbs></Breadcrumbs>

        <nav class="contentSidescroll__nav">
          <h5>Explore more content</h5>
          <button v-for="nav in segments" :key="nav.id" class="navigationLink" v-html="getNavTitle(nav)" @click="scrollTo(nav)"></button>
        </nav>

        <div class="contentSidescroll__content"  ref="SidescrollWrapper">
            <Segment v-for="segment in segments" :key="segment.id" :segment="segment.text.content" :id="segment.id"></Segment>
        </div>
    </article>
  </transition>
</template>

<script>
export default {
  props: ["properties", "pageData", "slug"],
  data() {
    return {
      ready: false,
      segments: []
    };
  },
  created() {
    this.initSegments();
  },
  async mounted() {
    this.ready = true;
    await this.$nextTick();
    
    this.$refs.SidescrollWrapper.addEventListener("wheel", (evt) => {
      evt.preventDefault();
      this.$refs.SidescrollWrapper.scrollLeft += evt.deltaY;
    })
  },
  methods: {
    initSegments() {
      this.segments = this.properties.segments.map((segment, i) => {
        segment.id = `segment__${i}`;
        return segment;
      })
    },
    getNavTitle(nav) {
      if(nav.customNavigationTitle !== '') {
        return nav.customNavigationTitle;
      } else {
        return nav.text.content.find(c => c.nsAlias.includes("headline")).text
      }
    },
    scrollTo(nav) {
      let element = document.getElementById(nav.id);
      element.scrollIntoView({ behavior: "smooth", block: "end", inline: "start" })
    },
    closeComponent() {
      this.$emit("closeComponent", this.slug);
    },
  },
  beforeUnmount() {
    this.$refs.SidescrollWrapper.removeEventListener("wheel", (evt) => {
      evt.preventDefault();
      this.$refs.SidescrollWrapper.scrollLeft += evt.deltaY;
    })
  }
};
</script>

<style lang="scss">

.contentSidescroll {
  display: flex;
  color: var(--main-color);

  .Breadcrumbs {
    left: 516px;
  }

  &__nav {
    height: 100%;
    width: 415px;
    min-width: 415px;
    padding: 200px 64px 0 64px;
    background: var(--light-grey);
    text-align: left;

    h5 {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 40px;
    }
  }

  &__content {
    display: flex;
    width: 100%;
    padding: 123px 0 0 0;    
    margin: 0 116px 38px 116px;
    white-space: nowrap;
    text-align: left;
    overflow-x: auto;
    overflow-y: hidden;

    .Segment {
      display: inline-block;
      width: 550px;
      min-width: 550px;
      margin-right: 120px;
      overflow-y: hidden;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}


</style>