export default function (manifest) {
    let pages = [];
    let metadata = manifest.Home.data;
    let scenes = manifest.ScenesFolder;
    let content = manifest.ContentFolder;
    let custom = manifest.CustomFolder;

    manifest.HotspotsFolder.forEach(page => flattenPages(page, null, ''))
    manifest.ContentFolder.map(content => formatContent(content))

    return {
        pages,
        scenes,
        content,
        custom,
        metadata
    }


    // Create flat array of all pages in the App.
    // includes id, type, routes and components.
    function flattenPages(node, parent, route) {
        let type = Object.keys(node.data.type)[0];
        route = createRoute(route, node, type);
        let page = formatPage(node, parent, route, type)

        pages.push(page)

        if (node.children) {
            node.children.forEach(c => flattenPages(c, node, route))
        }
    }

    function formatPage(node, parent, route, type) {
        // format Page for usable data from json manifest provided by Umbraco

        //get what type of content this page is
        let componentData = {};

        if (Object.entries(node.data.type)[0][1] !== null) {
            componentData = getComponentData(Object.entries(node.data.type)[0][1])
        }

        return {
            id: node.id,
            type: type,
            name: node.name,
            data: componentData,
            title: node.data.alternateTitle && node.data.alternateTitle !== '' ? node.data.alternateTitle : node.data.title,
            slug: sanitizeSlug(node.data.title),
            route: route,
            parent: parent ? sanitizeSlug(parent.data.title) : null,
            children: node.children ? getChildren(node.children) : [],
            hotspotData: {
                type: node.type,
                positionData: node.data.positionData
            },

            background: getNodeBackground(node),
            components: getComponentsForScene(componentData),
        }
    }
    
    // Create route for pages
    function createRoute(route, node, type) {
        // Determine seperator in url based on if the node is a scene or content to be openened.
        // '/' for scene
        // ':' for content
        let seperator = type == 'scene' ? '/' : ':'
        let slug = sanitizeSlug(node.data.title);
        return `${route}${seperator}${slug}`;
    }

    // Sanitize slugs for a clean url
    function sanitizeSlug(slug = '') {
        var s = slug
            .toLowerCase()
            .replace(/[^\w ]+/g, '')
            .replace(/ +/g, '-')

        return encodeURI(s).replace('%E2%80%93', '-')
    }

    // Enrich page.children with data
    function getChildren(children) {
        let tempChildren = [];
        tempChildren = children.map(child => {
            child.slug = sanitizeSlug(child.data.title);
            return child;
        })

        return tempChildren
    }
    
    // Get background object for scenes
    function getNodeBackground(node) {
        if(!node.data.type.scene)
            return null;

        let type = Object.keys(manifest.ScenesFolder.find(scene => scene.id === node.data.type.scene).data.background)[0];
        let bg = manifest.ScenesFolder.find(scene => scene.id === node.data.type.scene).data.background;
        
        let background = {
            video: type == 'video' ? bg.video : null,
            image: type == 'image' ? bg.image : null
        }

        return background
    }

    // get data inside component
    function getComponentData(id) {
        // Combine scenes and content into a single array
        let arrays = [...scenes, ...content];
    
        // If no item is found, it returns `null`.
        let result = arrays.find(item => item.id === id);
        return result !== undefined ? result : null;
    }

    // Get components for scene
    function getComponentsForScene(componentData) {
        
        let components =
            // Check if componentData is defined and not an empty object
            (componentData &&
            // Check if componentData has a data property
            componentData.data &&
            // Check if the data property has an array of components

            // If all the above conditions are true, map over the array of components and return a new array with the desired properties
            Array.isArray(componentData.data.components)) ?
            componentData.data.components.map(c => ({
                type: c.nsAlias,
                properties: {
                    ...c
                }
            }))
            // If any of the conditions fail, set components to an empty array
            : [];

            
        return components;
    }

    // Fomat Content Components 
    function formatContent(content) {
        content.title = content.name;
        content.slug = sanitizeSlug(content.name);
        return content;
    }
}