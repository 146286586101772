<template>
  <section class="Segment" :id="id">
    <component :is='getContentComponent(content)' :key="content.text" v-for='content in segment' :content='content'></component>
  </section>
</template>
<script>
import { markRaw } from 'vue';
export default {
  props: [
    "segment",
    "id"
  ],
  data() {
    return {
      contentComponents: {}
    }
  },
  created() {
    this.importcontentComponents();
  },
  methods: {
    importcontentComponents() {
        // Use require.context to dynamically import components from the ./hotspot-components subfolder
        const componentContext = require.context('./segment-content/', true, /\.vue$/);
        
        // Iterate over each component path and extract the component name
        componentContext.keys().forEach((componentPath) => {
            const componentName = componentPath.replace(/^.\/(.*)\.\w+$/, '$1');

            // Import the component and mark it as non-reactive using markRaw
            this.contentComponents[componentName] = markRaw(componentContext(componentPath).default);
        });
    },
    getContentComponent(content) {
        // Retrieve the corresponding component name from the "type" supplied by the Umbraco model.
        // If the component is not found, use the 'defaultHotspot' component
        const componentName = content.nsAlias;
        const component = this.contentComponents[componentName];

        if (!component) {
            console.log('%c No component found for type: ' + componentName, 'color: red;');
        }

        return component;
    },
  }
};
</script>

<style lang="scss">
.Segment {
  text-align: left;
  color: var(--main-color);
  max-height: 100%;
  overflow-y: auto;
  white-space: normal;
}
</style>