<template>
  <article class="backgroundText" :style="[getPosition, getSize, getTextStyle]" v-html="properties.text"></article>
</template>

<script>
export default {
  props: [
    "properties",
    "pageData"
  ],
  computed: {
    getPosition() {
      return {
          left: this.properties.positionData.xPosition + 'px',
          top: this.properties.positionData.yPosition + 'px'
      }
    },
    getSize() {
        return {
            width: this.properties.componentSize.width !== '' ? this.properties.componentSize.width : 'auto',
            height: this.properties.componentSize.height !== '' ? this.properties.componentSize.height : 'auto' 
        }
    },
    getTextStyle() {
      return {
        color: this.properties.textColor,
        'font-size': `${this.properties.fontSize}px`,
        'line-height': this.properties.lineHeight !== 0 ? `${this.properties.lineHeight}px` : ''
      }
    }
  }
};
</script>

<style lang="scss">
.backgroundText {
  position: absolute;

  * {
    font-size: inherit;
  }
}
</style>