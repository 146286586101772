import { createStore } from 'vuex'

//  Create a new store instance.
const store = createStore({
    state() {
        return {
            ready: false,
            
            // Touchkit Specific Variables
            scale: 1,
            allowNavigation: true,
            isFullscreen: false,
            contentComponentOpen: false,
            videoTransitions: [],

            backgroundWidth: 0,
            draggingBackground: false,


            //Semco Specific Variables
            showHotspotLabels: false
        }
    },
    mutations: {
        ready(state) {
            state.ready = true;
        },
        setScaleValue(state, value) {
            state.scale = value;
        },
        enableNavigation(state) {
            state.allowNavigation = true;
        },
        disableNavigation(state) {
            state.allowNavigation = false;
        },
        toggleFullscreen(state, bool) {
            state.isFullscreen = bool;
        },
        toggleContentComponentOpen(state, bool) {
            state.contentComponentOpen = bool;
        },
        storeVideoTransitions(state) {
            if(!app.api.PageManager.getCustomComponentFromType("videoTransitions"))
                return

                
            let umbracoTransitions = app.api.PageManager.getCustomComponentFromType("videoTransitions").data.transitions;
            let transitions = [];
        
            transitions = umbracoTransitions.map((transition, i) => {
              transition.id = `transition_${i}`;
              transition.from = transition.from ? app.api.PageManager.getRouteFromID(transition.from) : null;
              transition.to = transition.to ? app.api.PageManager.getRouteFromID(transition.to) : null;
              return transition;
            })
        
            state.videoTransitions = transitions;
        },
        setBackgroundWidth(state, value) {
            state.backgroundWidth = value;
        },
        toggleDraggingBackground(state, bool) {
            state.draggingBackground = bool;
        },

        toggleShowHotspotLabels(state) {
            state.showHotspotLabels = !state.showHotspotLabels
        } 
    },
    getters: {
        ready(state) {
            return state.ready;
        },
        getScaleValue(state) {
            return state.scale;
        },
        canNavigate(state) {
            return state.allowNavigation;
        },
        isFullscreen(state) {
            return state.isFullscreen;
        },  
        getContentComponentOpen(state) {
            return state.contentComponentOpen;
        },
        getVideoTransitions(state) {
            return state.videoTransitions;
        },
        getBackgroundWidth(state) {
            return state.backgroundWidth;
        },
        isBackgroundDragged(state) {
            return state.draggingBackground;
        },

        isHotspotLabelsVisible(state) {
            return state.showHotspotLabels;
        }
    },
    actions: {
        ready(context) {
            context.commit('ready')
        }
    }
})

export default store;
