<template>
  <div id="AspectContainer" ref="appFrame">
    <slot></slot>
  </div>
</template>

<script>
export default {
  data: () => {
    return {
      scale: 1,
    };
  },
  mounted() {
    this.updateFrameSize();
    window.addEventListener("resize", this.updateFrameSize);
  },
  methods: {
    updateFrameSize() {
      // NOTE: Scaling handler. uses css transform: scale on the #app id to scale the entire scene.
      // The math to determine how much scale to apply to the scene.
      // It will make the app as big as it can be while maintaining its aspect ratio. The app will be centered.
      // With a purpose of displaying a fixed 16:9 app.

      


      var aspectRatioOptions = {
        centerVertically: true,
        centerHorizontically: true,
        scrollable:true,
      };

      var windowResolution = {
        width: window.innerWidth,
        height: window.innerHeight,
      };

      var appResolution = {
        width: this.$refs.appFrame.offsetWidth,
        height: this.$refs.appFrame.offsetHeight,
       
      };

      //console.log(appResolution.width, appResolution.height)

      var windowAspectRatio = windowResolution.width / windowResolution.height;
      var appAspectRatio = appResolution.width / appResolution.height;

      var windowAspectRatioIsTallerThanApp = windowAspectRatio > appAspectRatio;

      if (windowAspectRatioIsTallerThanApp) {
        // NOTE: Window is taller than app
        // NOTE: Resize app based on height
        var heightDifference = windowResolution.height / appResolution.height;
        this.$refs.appFrame.style.transform = "scale(" + heightDifference + ")";
        this.scale = heightDifference;
      } else {
        // NOTE: Window is wider than app
        // NOTE: Resize app based on width
        var widthDifference = windowResolution.width / appResolution.width;
        this.$refs.appFrame.style.transform = "scale(" + widthDifference + ")";
        this.scale = widthDifference;
      }

      // NOTE: Center the app container on both axis
      var appClientRect = this.$refs.appFrame.getBoundingClientRect();
      var appResolutionScaled = {
        width: appClientRect.width,
        height: appClientRect.height,
      };

      var appOffset = {
        x: 0,
        y: 0,
      };

      if (
        aspectRatioOptions.centerHorizontically &&
        appResolutionScaled.width < windowResolution.width
      ) {
        appOffset.x = (windowResolution.width - appResolutionScaled.width) / 2;
      }

      if (
        aspectRatioOptions.centerVertically &&
        appResolutionScaled.height < windowResolution.height
      ) {
        appOffset.y =
          (windowResolution.height - appResolutionScaled.height) / 2;
      }

      this.$refs.appFrame.style.transformOrigin = "top left";
      this.$refs.appFrame.style.left = appOffset.x + "px";
      this.$refs.appFrame.style.top = appOffset.y + "px";
      this.$refs.appFrame.style.margin = "0";

      this.$store.commit('setScaleValue', this.scale);
    },
  },
};
</script>

<style lang="scss">
#AspectContainer {
    position: absolute;
    width: 1920px;
    height: 1080px;
    touch-action: none; // avoid scrolling on touch devices
    // overflow: hidden;
}
</style>