const config = {
    name: 'Semco Maritime',
    language: 'en',

    mediaFolder: '/media/',
    manifest: 'assets/json/@lang/manifest.json'
}


if (window.location.hostname === "localhost") {
    config.manifest = 'https://semcorenewablesumbraco.azurewebsites.net/output/event/assets/json/@lang/manifest.json';
    config.mediaFolder = 'https://semcorenewablesumbraco.azurewebsites.net/media/';
} else if (window.location.hostname === "") {
    config.mediaFolder = 'media/';
}


export default config