<template>
    <div class="PageScroller" :style="{ transform: 'translateX(' + scrollX + 'px)' }" @pointerdown="handlePointerDown">
        <slot></slot>

    </div>
    <div class="PageScrollbar" :style="{ width: windowWidth + 'px' }" v-show="scrollbarVisible">
        <div class="handle"
            :style="{ transform: 'translateX(' + scrollbarPosition + 'px)', width: scrollbarWidth + 'px' }"></div>
    </div>
</template>

<script>

export default {
    props: ["enabled"],

    data() {
        return {

            // master scroll 
            scrollX: 0,

            // sizing 
            globalScale: null,
            backgroundWidth: null,
            windowWidth: null,
            scrollMinMax: null,

            // auto scrolling
            allowAnimation: false,

            // scrollbar 
            scrollbarPosition: 0,
            scrollbarWidth: 0,
            scrollbarVisible: false,

        }
    },

    mounted() {

        if (!this.enabled)
            return;


        // handle stuff when resizing
        window.addEventListener("backgroundLoaded", this.onRezize);

        // handle stuff when resizing
        window.addEventListener("resize", this.onRezize);

        // local scroll vars
        let offsetX = 0;
        let clientX = 0;
        let animationFrameOccupied = false;
        let previousScrollX = 0;
        let throwSpeed = 0;
        let throwDamping = .95;


        /// POINTER EVENTS ///
        this.handlePointerDown = (event) => {
            // no scrolling when: 
            // 1) window is wider than background 
            // 2) clicking something other than leftclick
            if (this.scrollMinMax <= 0 || event.button !== 0) {
                this.allowAnimation = true;
                return;
            }

            //console.log(event.pointerType, event.clientX);
            event.preventDefault();
            this.$store.commit('toggleDraggingBackground', true)

            // drag offset position
            offsetX = event.clientX / this.globalScale - this.scrollX;
            
            // stop animating!
            this.allowAnimation = false;

            const handlePointerMove = (event) => {
                clientX = event.clientX / this.globalScale;
                if (!animationFrameOccupied) {
                    animationFrameOccupied = true;
                    requestAnimationFrame(calcScrollX);
                }
            };

            const handlePointerUp = () => {
                window.removeEventListener('pointermove', handlePointerMove);
                window.removeEventListener('pointerup', handlePointerUp);
                this.$store.commit('toggleDraggingBackground', false);

                // throw!
                startThrowAnimation();
            };

            window.addEventListener('pointermove', handlePointerMove);
            window.addEventListener('pointerup', handlePointerUp);
        };


        /// CALCULATE X WHILE DRAGGING ///
        const calcScrollX = () => {

            // calculate new drag distance
            let dragDelta = clientX - offsetX;
            this.scrollX = Math.max(-this.scrollMinMax, Math.min(this.scrollMinMax, dragDelta));

            // meassuring current speed (if thrown)
            throwSpeed = this.scrollX - previousScrollX;
            previousScrollX = this.scrollX;

            // ready for next drag event
            animationFrameOccupied = false;

            this.updateScrollbar();

        }

        /// CALCULATE X AFTER THROW ///
        const animateThrow = () => {

            if (this.allowAnimation) {

                var absoluteThrowSpeed = Math.abs(throwSpeed)
                if (absoluteThrowSpeed > 0.1) {
                    // degrade throwspeed
                    throwSpeed = throwSpeed * throwDamping;
                } else {
                    throwSpeed = 0;
                }

                // Update the scroll position
                this.scrollX += throwSpeed;

                this.updateScrollbar()

                // slow down if border crossed
                if (outOfLimits()) {
                    throwDamping = 0.2;
                }


                if (absoluteThrowSpeed > 0) {
                    // Continue animating until throwspeed is degraded completely
                    requestAnimationFrame(animateThrow);

                } else {
                    // check if limits are crossed
                    var limitsCrossed = outOfLimits();
                    if (!limitsCrossed) {
                        // throw animation done
                        this.allowAnimation = false;
                    } else {
                        // go back to limit
                        throwDamping = 0.2;
                        if (limitsCrossed == 'start') {
                            this.animateToX(-this.scrollMinMax);
                        } else if (limitsCrossed == 'end') {
                            this.animateToX(this.scrollMinMax);
                        }
                    }
                }
            }
        };

        const startThrowAnimation = () => {
            // reset damping
            throwDamping = 0.95;
            // Start the animation if not already animating
            if (!this.allowAnimation) {
                this.allowAnimation = true;
                requestAnimationFrame(animateThrow);
            }
        };

        const outOfLimits = () => {
            if (this.scrollX < -this.scrollMinMax) {
                return "start"
            }
            if (this.scrollX > this.scrollMinMax) {
                return "end"
            }
            return null;
        }
    },



    methods: {

        getSizeProps() {
            this.globalScale = this.getGlobalScale();
            this.backgroundWidth = this.getBackgroundWidth();
            this.windowWidth = this.getWindowWidth();
            this.scrollMinMax = this.getScrollMinMax();
            this.updateScrollbar();


            /* console.table({
                "globalScale": this.globalScale,
                "backgroundWidth": this.backgroundWidth,
                "windowWidth": this.windowWidth,
                "scrollMinMax": this.scrollMinMax,
            }); */



            // if still nulls try again
            if (!this.globalScale || !this.backgroundWidth)
                setTimeout(() => {
                    this.getSizeProps();
                }, 200);

        },

        getBackgroundWidth() {
            return this.$store.getters.getBackgroundWidth;
        },

        getGlobalScale() {
            return this.$store.getters.getScaleValue;
        },

        getWindowWidth() {
            return window.innerWidth / this.globalScale;
        },

        getScrollMinMax() {
            return (this.backgroundWidth - this.windowWidth) / 2;
        },

        onRezize() {
            // refresh size props
            this.getSizeProps();

            // center scrolling
            this.allowAnimation = true;
            this.animateToX(0);

        },

        animateToX(xPos) {

            var diff = (xPos - this.scrollX);
            if (Math.abs(diff) > 1 && this.allowAnimation) {
                this.scrollX += diff / 10;

                requestAnimationFrame(() => {
                    this.animateToX(xPos)
                    this.updateScrollbar();
                });
            }

        },

        updateScrollbar() {
            var viewPortPct = this.windowWidth / this.backgroundWidth;
            this.scrollbarPosition = - this.scrollX * viewPortPct;
            this.scrollbarWidth = viewPortPct * this.windowWidth;
            this.scrollbarVisible = (viewPortPct < 1 && this.enabled);
        }

    },
    unmounted() {
        window.removeEventListener("backgroundLoaded", this.onRezize);
        window.removeEventListener("resize", this.onRezize);
    }
};


</script>


<style lang="scss">
.PageScroller {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 1;
    touch-action: auto;


}

.PageScrollbar {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 10px;
    bottom: 0px;
    background-color: black;
    z-index: 4;

    .handle {
        position: absolute;
        height: 10px;

        background-color: var(--main-color);
        z-index: 5;
    }
}
</style>