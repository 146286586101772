<template>
    <h3 class="headlineMedium" v-html="content.text"></h3>
</template>
<script>
export default {
    props: ["content"]
}
</script>
<style lang="scss">
.headlineMedium {
    position: relative;
    font-size: 30px;
    font-weight: 700;
    padding-left: 74px;
    line-height: 38px;
    margin-bottom: 50px;

    p {
        margin-bottom: 0;
    }

    &::before {
        content: '';
        position: absolute;
        height: 86%;
        width: 50px;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background: url(@/assets/img/ui/stripes.png);
    }
}
</style>