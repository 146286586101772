<template>
    <article id="BurgerNavigation">
        <button class="uibutton" :class="{'uibutton--open': open}" @click="open = !open" v-if="!contentComponentOpen && !onHomePage" aria-label="toggle navigation"></button>
        <transition name="toggleContentComponent">
            <nav v-if="open">
                <div class="BurgerNavigation__topics">
                    <button v-for="topic in topics" :key="topic.title" class="link" v-html="topic.title" :class="{'link--active': topic.active}" @click="changeTopic(topic)"></button>
                </div>
                <div class="BurgerNavigation__lists">
                    <div v-for="list in computedBurgerNavigationLists" :key="list.title">
                        <router-link :to="list.scene" class="navigationLink" :class="{'navigationLink--active': list.active}">{{list.title}}</router-link>
                        <router-link v-for="link in list.links" :key="link.title" :to="link.link" class="navigationLink">{{link.title}}</router-link>
                    </div>
                </div>
            </nav>
        </transition>
    </article>
</template>
<script>

export default {
    props: ["onHomePage"],
    data() {
        return {
            open: false,
            topics: []
        }
    },
    created() {
        this.initTopics();
    },
    watch: {
        $route() {
            this.open = false;
        },
        open() {
            this.checkWhatTopicActive();
        }
    },
    methods: { 
        initTopics() {
            this.topics = this.getTopicsData().map((topic) => { 
                topic.active = app.api.PageManager.getCurrentRoute().includes(app.api.PageManager.getRouteFromID(topic.scene));
                topic.lists.map(list => { 
                    list.title = app.api.PageManager.getPageFromID(list.scene).title;
                    list.scene = app.api.PageManager.getRouteFromID(list.scene);
                    list.active = app.api.PageManager.getCurrentRoute().includes(list.scene)
                    list.links.map(link => {
                        link.title = app.api.PageManager.getPageFromID(link.link) ? app.api.PageManager.getPageFromID(link.link).title : app.api.PageManager.getContentComponentFromID(link.link).name
                        link.link = `${list.scene}:${app.api.PageManager.getContentComponentFromID(link.link).slug}`
                        return link;
                    })
                    return list
                })
                return topic;
            })
        },
        getTopicsData() {
            return app.api.PageManager.getCustomComponentFromType("burgerNavigation").data.topics
        },
        changeTopic(topic) {
            this.topics.forEach(topic => topic.active = false);
            topic.active = true; 
        },
        checkWhatTopicActive() {
            this.topics.forEach(topic => {
                topic.active = app.api.PageManager.getCurrentRoute().includes(app.api.PageManager.getRouteFromID(topic.scene));
                topic.lists.forEach(list => {
                    list.active = app.api.PageManager.getCurrentRoute().includes(list.scene)
                })
            })
        }
    },
    computed: {
        computedBurgerNavigationLists() {
            return this.topics.find(topic => topic.active === true).lists
        },
        contentComponentOpen() {
            return this.$store.getters.getContentComponentOpen;
        }
    }
}
</script>
<style lang="scss">
#BurgerNavigation {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    
    * { 
        pointer-events: all;
    }

    .uibutton {
        position: absolute;
        top: 40px;
        right: 40px;
        bottom: auto;
        left: auto;
        background-image: url(@/assets/img/ui/burger.png);
        z-index: 2;

        &:hover {
            background-image: url(@/assets/img/ui/burger-hover.png);
        }

        &--open {
            background-image: url(@/assets/img/ui/close.png);
            &:hover {
                background-image: url(@/assets/img/ui/close-hover.png);
            }
        }
    }

    &>nav {
        position: relative;
        width: 100%;
        height: 1080px;
        background: var(--light-grey);
        z-index: 1;

        .BurgerNavigation__topics {
            position: absolute;
            top: 166px;
            left: 50%;
            transform: translateX(-50%);
            .link {
                font-size: 45px;
                padding: 0 45px;

                &::before {
                    font-size: 60px;
                    font-weight: 300 !important;
                    right: -11px;
                }
            }
        }

        .BurgerNavigation__lists {
            position: absolute;
            top: 306px;
            width: 100%;
            display: flex;
            justify-content: center;

            &>div {
                display: flex;
                flex-direction: column;
                width: 200px;
                text-align: left;
                margin: 0 50px;
                line-height: 20px;

                .navigationLink {
                    &:first-of-type {
                        font-weight: bold;
                    }
                }
            }
        }
    }
}


.navigationLink {
    position: relative;
    color: var(--main-color);
    width: 100%;
    font-size: 16px;
    margin-bottom: 22px;
    transition: ease color 0.15s;

    &:hover {
        color: var(--secondary-color);
    }

    &::before {
        content: '';
        position: absolute;
        right: calc(100% + 15px);
        width: 18px;
        height: 15px;
        top: 2px;
        background-image: url(@/assets/img/ui/arrow.png);
        background-size: 90%;
        background-position: center;
        background-repeat: no-repeat;
    }

    &--active {
        cursor: default;
        color: var(--secondary-color);
        text-shadow: 0px 0px 1px var(--secondary-color);

        &:hover {
            color: var(--secondary-color);
        }
    }

    p {
        margin-bottom: 0;
    }
}

</style>