<template>
    <h3 class="headlineSmall" v-html="content.text"></h3>
</template>
<script>
export default {
    props: ["content"]
}
</script>
<style lang="scss">
.headlineSmall {
    position: relative;
    font-size: 30px;
    font-weight: 700;
    line-height: 38px;
    margin-bottom: 50px;

    p {
        margin-bottom: 0;
    }
}
</style>