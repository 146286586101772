<template>
    <transition name="HotspotEntrance" v-on:after-enter="setHotspotWidth">
        <router-link class="Hotspot" v-show="ready" :to="hotspot.route" :class="[getHotspotMutatorClasses, getHotspotContentClass, getHotspotTypeClass]" :style="[getPosition, offsetFlippedHotspotAnimation]" @mouseover="mouseOver = true" @mouseleave="mouseOver = false" @click="hotspotPressed">
            <div class="Hotspot__button" :style="[getAlternateHotspotIcon]"></div>
            <span v-html="getHotspotTitle" :style="showLabelAnimation" ref="hotspotLabel"></span>
        </router-link>
    </transition>
</template>

<script>

export default {
    props: ['hotspot'],
    data() {
        return {
            ready: false,
            mouseOver: false,
            offsetWidth: 0
        }
    },
    created() {
        this.renderHotspotCascade();

    },
    methods: {
        renderHotspotCascade() {
            let delay = 0;
            delay = (this.hotspot.index * 200) + 400;

            setTimeout(() => {
                this.ready = true;
            }, delay);
        },
        getRandomAnimationDelay(min, max) {
            //return a random value for randomly timed animations
            return Math.random() * (max - min) + min;
        },
        hotspotPressed() {
            this.$emit('hotspotPressed', this.hotspot)
        },
        setHotspotWidth() {
            if(!this.$refs.hotspotLabel)
                return

            this.offsetWidth = this.$refs.hotspotLabel.offsetWidth;
        }
    },
    computed: {
        getPosition() {
            return {
                left: this.hotspot.data.positionData.xPosition + 'px',
                top: this.hotspot.data.positionData.yPosition + 'px'
            }
        },
        getHotspotTitle() {
            return this.hotspot.data.alternateTitle && this.hotspot.data.alternateTitle !== '' ? this.hotspot.data.alternateTitle : this.hotspot.data.title;
        },
        getHotspotTypeClass() {
            let hotspotPrefix = "Hotspot--"            

            switch(this.hotspot.data.hotspotType) {
                case "No Label":
                    return `${hotspotPrefix}NoLabel` 

                case "Seethrough": 
                    return `${hotspotPrefix}Seethrough`

                // more types can be added here through the backend hotspotType data value.

                default: 
                    return
            }
        },
        getHotspotMutatorClasses() {
            return {
                'Hotspot--flipped': this.hotspot.data.flipped,
                'Hotspot--hidden': this.hotspot.data.hidden,
                'Hotspot--customicon': this.hotspot.data.alternateHotspotIcon !== null
            }
        },
        getHotspotContentClass() {
            if(this.hotspot.componentData) {
                return `Hotspot--${this.hotspot.componentData.type}`
            } else {
                return
            }
        },
        getContinuousHotspotAnimation() {
            // assign the looping animation on hotspots, as well as keeping them synced with resetHotspot.
            if(this.showLabelsGlobally)
                return

            return {
                'animation-name': "hotspotLoopPulse",
                'animation-iteration-count': 'infinite',
                'animation-duration': '15s',
                'animation-delay': this.getRandomAnimationDelay(2, 14).toFixed(1) + 's'
            }
        },
        getAlternateHotspotIcon() {
            if(this.hotspot.data.alternateHotspotIcon !== null) {
                return {
                    'background-image': `url(${app.api.Utils.getMediaPath(this.hotspot.data.alternateHotspotIcon.src)})`
                };
            }
        },
        showLabel(){ 
            return this.showLabelsGlobally || (!this.showLabelsGlobally && this.mouseOver)
        },
        showLabelsGlobally() {
            return this.$store.getters.isHotspotLabelsVisible
        },

        showLabelAnimation() {
            if(this.getHotspotTypeClass) {
                if(this.getHotspotTypeClass.includes('Seethrough')) 
                return
            }


            if(this.hotspot.data.flipped) {
                return {
                    'margin-right': this.showLabel ? '' : `-${this.offsetWidth}px`
                }
            } else {
                return {
                    'margin-left': this.showLabel? '' : `-${this.offsetWidth}px` 
                }
            }
        },
        offsetFlippedHotspotAnimation() {
            if(this.getHotspotTypeClass) {
                if(this.getHotspotTypeClass.includes('Seethrough')) 
                return
            }

            if(this.hotspot.data.flipped) {
                return {
                    'left': this.showLabel ? `${this.hotspot.data.positionData.xPosition}px` : `${this.hotspot.data.positionData.xPosition + this.offsetWidth}px`
                }
            }
        }
    }
}
</script>


<style lang="scss">
$hotspot-height: 45px;

.Hotspot {
    display: flex;
    background-color: var(--main-color);
    border: 2px solid var(--secondary-color);
    border-radius: 1000px;
    color: var(--secondary-color);
    overflow: hidden;
    transition: left 0.4s var(--tk-easeinout);

    .Hotspot__button {
        display: flex;
        justify-content: center;
        align-items: center;
        height: $hotspot-height;
        width: $hotspot-height;
        background-image: url(@/assets/img/ui/plus.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 25px;
        background-color: var(--main-color);
        border-radius: 1000px;
        color: var(--secondary-color);
        z-index: 2;
    }
    
    span {
        position: relative;
        display: flex;
        align-items: center;
        align-self: center;
        white-space: pre-wrap;
        font-size: 18px;
        padding: 0 26px 0 3px;
        border-left: none;
        transition: margin 0.4s var(--tk-easeinout);
    }
    
    
    &--flipped {
        flex-direction: row-reverse;
        
        span {
            padding: 0 3px 0 26px;
        }
    }
    
    // Styles for specifc components that hotspot can open
    &--fullscreenVideo, &--fullscreenIframePlayer {
        .Hotspot__button {
            //background-image: url(@/assets/img/ui/play.svg);
        }
    }
    &--externalLink {
        .Hotspot__button {
            background-image: url(@/assets/img/ui/globe.svg);
        }
    }
    &--mail {
        .Hotspot__button {
            background-image: url(@/assets/img/ui/mail.svg);
        }
    }

    // Styles for specific hotspot types defined by getHotspotTypeClass()
    &--NoLabel {
        span {
            display: none;
        }
    }

    &--Seethrough {
        transition: opacity 0.15s ease;
        opacity: 0.85;

        .Hotspot__button {
            background-image: url(@/assets/img/ui/arrow.png);
            transform: rotate(180deg);
            background-size: 20px;
        }

        &.Hotspot--flipped {
            .Hotspot__button {
                transform: rotate(0deg);
            }
        }

        &:hover {
            opacity: 1;
        }
    }
}

/* entrance animation */
.HotspotEntrance-enter-active {
    transition-duration: 1s; 
    transition-property: transform, opacity;
    transition-timing-function: var(--tk-easeinout);
}
.HotspotEntrance-leave-active {
    transition-duration: 0.3s; 
    transition-property: transform, opacity;
    transition-timing-function: var(--tk-easeinout);
}
.HotspotEntrance-enter-from {
    transform: translateY(-300%);
    opacity: 0;
}
.HotspotEntrance-leave-to {
    opacity: 0;
}

/* default*/
@keyframes hotspotLoopPulse {
    0% {
        box-shadow: 0 0 20px 0 rgba(255, 255, 255, 0.3);
        //transform: scale(1.12);
    }

    7% {
        box-shadow: 0 0 20px 100px rgba(255, 255, 255, 0);
        //transform: scale(1.04);
    }

    10% {
        box-shadow: 0 0 20px 0 rgba(255, 255, 255, 0);
        //transform: scale(1);
    }

    100% {
        box-shadow: 0 0 20px 0 rgba(255, 255, 255, 0);
        //transform: scale(1);
    }
}
</style>