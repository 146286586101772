<template>
  <section class="NullComponent">
    <transition name="fade">
      <span v-if="ready">
        Render Error:
        <i>Unknown component: <strong>"{{ properties.nsAlias === undefined ? "null or undefined" : properties.nsAlias
        }}"</strong>, has it been registered in the Component Repo?</i>
      </span>
    </transition>
  </section>
</template>

<script>
export default {
  props: [
    "properties"
  ],
  data() {
    return {
      ready: false
    }
  },
  mounted() {
    this.ready = true
  },
};
</script>

<style lang="scss">
.NullComponent {
  border: solid 2px red;
  padding: 20px 10px;
  background: rgba(0, 0, 0, 0.5);
  color: #f4f4f4;
  right: 0;
  bottom: 0;

  strong {
    font-weight: bold;
  }

  i {
    font-style: italic;
  }
}

.fade-enter-active,
.fade-leave-active  {
  transition: opacity 0.15s ease-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>