<template>
    <router-link :to="homeURL" id="logo">
        <transition name="fade">
            <img src="@/assets/img/ui/logo-semco.png" v-if="!isOnHomeUrl">
            <img src="@/assets/img/ui/logo-semco-white.png" v-else>
        </transition>
    </router-link>
</template>
<script>
export default {
    computed: {
        homeURL() {
            return app.api.PageManager.getRootPage().route
        },
        isOnHomeUrl() {
            this.$route;
            return app.api.PageManager.getRootPage().route == app.api.PageManager.getCurrentRoute(); 
        }
    }
}
</script>
<style lang="scss" scoped>
    #logo {
        position: absolute;
        top: 40px;
        left: 37px;
        z-index: 2;
        cursor: pointer;

        &>img {
            position: absolute;
        }
    }
</style>