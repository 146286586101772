<template>
    <nav id="Breadcrumbs">
        <router-link v-for="crumb in breadcrumbs" :to="crumb.route" :key="crumb.route" v-html="crumb.title"></router-link>
    </nav>
</template>
<script>
export default {
    computed: {
        breadcrumbs() {
            this.$route;
            return app.api.PageManager.getBreadcrumbs().filter(crumb => crumb !== undefined);
        }
    }
}
</script>
<style lang="scss">
#Breadcrumbs {
    position: absolute;
    display: flex;
    top: 80px;
    left: 45px;
    color: var(--secondary-color);
    max-width: var(--header-max-width);
    padding: var(--content-padding);
    pointer-events: none;
    font-size: 20px;
    z-index: 2;

    a {
        font-weight: bold;
        padding: 0 10px;
        pointer-events: all;
        position: relative;

        &::after {
            content: '/';
            position: absolute;
            pointer-events: none;
            margin-left: 4px;
            color: var(--secondary-color);
        }

        &:last-of-type {
            cursor: default;
            color: var(--main-color);

            &::after {
                content: none;
            }
        }

        &:hover {
            color: var(--main-color);
        }
    }
}
</style>