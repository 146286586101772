<template>
  <nav id="UiLayer">
      <Logo></Logo>
      <BackButton v-if="!contentComponentOpen" :onHomePage="onHomePage"></BackButton>
      <FullscreenButton v-if="!contentComponentOpen" :onHomePage="onHomePage"></FullscreenButton>
      <HotspotLabelsButton v-if="!contentComponentOpen" :onHomePage="onHomePage"></HotspotLabelsButton>
      <CustomNavigation v-if="hasCustomNavigation && !contentComponentOpen" :onHomePage="onHomePage"></CustomNavigation>
      <BurgerNavigation :onHomePage="onHomePage"></BurgerNavigation>
  </nav>
</template>
<script>
import Logo from "../ui/Logo.vue";
import Breadcrumbs from "../ui/Breadcrumbs.vue";
import BackButton from '../ui/BackButton.vue';
import FullscreenButton from '../ui/FullscreenButton.vue';
import HotspotLabelsButton from '../ui/HotspotLabelsButton.vue';
import CustomNavigation from '../ui/CustomNavigation.vue';
import BurgerNavigation from '../ui/BurgerNavigation.vue';

export default {
  components: {
    // Ui Components
    Logo,
    Breadcrumbs,
    BackButton,
    FullscreenButton,
    HotspotLabelsButton,
    CustomNavigation,
    BurgerNavigation
  },
  data() {
    return {
    };
  },
  methods: {
  },
  computed: {
    onHomePage() {
      this.$route;
      return false;
      return app.api.PageManager.onHomePage();
    },
    hasCustomNavigation() {
      this.$route;
      let shownav = false;
      let laproomids = app.api.PageManager.getCustomComponentFromType("customNavigation").data.showNavigation.map(id => {
        return app.api.PageManager.getRouteFromID(id.scene)
      })

      laproomids.forEach(route => {
        if(app.api.PageManager.getCurrentRoute().endsWith(route))
         shownav = true
      })
      
      return shownav; 
    },
    contentComponentOpen() {
      return this.$store.getters.getContentComponentOpen;
    }
  }
};
</script>

<style lang="scss">
#UiLayer {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;

  & > button, .uibutton {
    position: absolute;
    height: 50px;
    width: 50px;
    bottom: 40px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 1000px;
    transition: background-image 0.2s var(--tk-easeinout);
    box-shadow: var(--shadow-medium);
  }

  * {
    pointer-events: all;
  }
}
</style>