<template>
    <h2 class="headlineBig" v-html="content.text"></h2>
</template>
<script>
export default {
    props: ["content"]
}
</script>
<style lang="scss">
.headlineBig {
    position: relative;
    font-size: 45px;
    font-weight: 700;
    padding-left: 74px;
    

    &::before {
        content: '';
        position: absolute;
        height: 86%;
        width: 50px;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background: url(@/assets/img/ui/stripes.png);
    }
}
</style>