<template>
    <div class="bodyText" v-html="content.text"></div>
</template>
<script>
export default {
    props: ["content"]
}
</script>
<style lang="scss">

.bodyText {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 40px;
}
</style>