<template>
    <div class="imageSegment" :class="{'imageSegment--hasLink': content.link}">
        <img :src="computedSrc" @click="openContent">
        <button v-if="content.link"></button>
    </div>
</template>
<script>
export default {
    props: ["content"],
    methods: {
        openContent() {
            if(this.content.link)
                app.api.PageManager.openExplicitContentFromID(this.content.link)
        }
    },
    computed: {
        computedSrc() {
            if(this.content.image)
                return app.api.Utils.getMediaPath(this.content.image.src)
        }
    }
}
</script>
<style lang="scss">
$button-size: 36px;

.imageSegment {
    position: relative;
    width: 100%;
    margin-bottom: 30px;

    img {
        width: 100%;
    }

    button {
        position: absolute;
        right: 12px;
        bottom: 16px;
        width: $button-size;
        height: $button-size;
        border-radius: 100%;
        box-shadow: var(--shadow-medium);
        z-index: 2;
        transition: transform var(--tk-easeinout) 0.15s;
        background-image: url(@/assets/img/ui/fullscreen.png);
        background-size: 100%;
    }

    &--hasLink {
        cursor: pointer;
    }    

    &:hover {
        button {
            transform: scale(1.1);
        }
    }
}

</style>