<template>
    <nav class="HotspotContainer">
        <component :is='getHotspotComponent(hotspot)' 
            v-for='(hotspot) in renderedHotspots'
            :key='hotspot.id' :hotspot='hotspot'
            @hotspotPressed="hotspotPressed">
        </component>
    </nav>
</template>

<script>
import { markRaw } from 'vue';

export default {
    props: ['hotspots'],
    data() {
        return {
            hotspotComponents: {}
        }
    },
    created() {
        this.importHotspotComponents();
    },
    methods: {
        importHotspotComponents() {
            // Use require.context to dynamically import components from the ./hotspot-components subfolder
            const componentContext = require.context('./hotspot-components/', true, /\.vue$/);
            
            // Iterate over each component path and extract the component name
            componentContext.keys().forEach((componentPath) => {
                const componentName = componentPath.replace(/^.\/(.*)\.\w+$/, '$1');

                // Import the component and mark it as non-reactive using markRaw
                this.hotspotComponents[componentName] = markRaw(componentContext(componentPath).default);
            });
        },
        getHotspotComponent(hotspot) {
            // Retrieve the corresponding component name from the "type" supplied by the Umbraco model.
            // If the component is not found, use the 'defaultHotspot' component
            const componentName = hotspot.type;
            const component = this.hotspotComponents[componentName] || this.hotspotComponents['defaultHotspot'];

            if (!component) {
                console.log('%c No hotspot component found for type: ' + componentName + '. Resorting to defaultHotspot.', 'color: red;');
            }

            return component;
        },

        getRoute(hotspot) {
            let hotspotReferenceID = Object.values(hotspot.data.type)[0];

            if(Object.keys(hotspot.data.type)[0] == 'scene') {
                return app.api.PageManager.getRouteFromID(hotspotReferenceID);
            } else if (Object.keys(hotspot.data.type)[0] == 'content') {
                return app.api.PageManager.getCurrentRoute().split(":").slice(0)[0] + `:${app.api.PageManager.getComponentSlugFromHotspotID(hotspot.id)}`
            } else {
                return '/'
            }
        },
        hotspotPressed(e) {
            this.$emit('hotspotPressed', e)
        }
    },
    computed: {
        renderedHotspots() {
            let hotspots = this.hotspots.filter(hotspot => hotspot.data.hidden === false).map((renderedhotspot, i) => {
                renderedhotspot.index = i;
                renderedhotspot.route = this.getRoute(renderedhotspot);
                renderedhotspot.componentData = Object.keys(renderedhotspot.data.type)[0] === 'content' ? app.api.PageManager.getComponentDataFromHotspotID(renderedhotspot.id) : null
                return renderedhotspot;
            });

            return hotspots;
        }
    }
}
</script>

<style lang="scss">
.HotspotContainer {
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none !important;
    z-index: 3;
    &>* {
        pointer-events: all;
    }
}

.Hotspot {
    position: absolute;
    cursor: pointer;

    &--hidden {
        display: none;
    }
}
    
</style>