<template>
    <nav class="Breadcrumbs">
        <router-link v-for="crumb in breadcrumbs" class="link" :to="crumb.route" :key="crumb.route" v-html="crumb.title"></router-link>
    </nav>
</template>
<script>
export default {
    computed: {
        breadcrumbs() {
            this.$route;
            let breadcrumbs = app.api.PageManager.getBreadcrumbs().filter(crumb => crumb !== undefined)
            breadcrumbs.shift();
            return breadcrumbs;
        }
    }
}
</script>
<style lang="scss">
.Breadcrumbs {
    position: absolute;
    top: 30px;
    left: 688px;
    z-index: 2;

    .link {
        font-size: 15px;

        &::before {
            font-size: 18px;
        }

        &:last-of-type {
            cursor: default;
            text-shadow:0px 0px 1px var(--main-color);
            color: var(--main-color) !important;
            pointer-events: none;
        }
    }
}
</style>