<template>
    <div class="subheadline" v-html="content.text"></div>
</template>
<script>
export default {
    props: ["content"]
}
</script>
<style lang="scss">
.subheadline {
    font-size: 16px;
    line-height: 22px;
    font-size: 20px;
    line-height: 24px;
    
    > p {
        margin-bottom: 80px;
        font-family: 'RobotoSlab', Arial, Helvetica, sans-serif;
    }
}
</style>